import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { useUser, useAuth } from "@clerk/clerk-react";

const Balance = ({ updateTrigger, currentBalance, setCurrentBalance }) => {
  const { isLoaded, isSignedIn, user } = useUser();
  const { getToken } = useAuth();

  useEffect(() => {
      const fetchBalance = async () => {
          try {
              const response = await fetch('https://api.moneylinez.xyz/get-user-balance', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${await getToken()}`
                  },
              });
              const data = await response.json();
              setCurrentBalance(data[0].balance || 0);
          } catch (err) {
              console.error(err.message);
          }
      };

      fetchBalance();
  }, [updateTrigger]);

  return (
    <div>
      <div>${Number(currentBalance).toFixed(2)}</div>
    </div>
  );

};

export default Balance;
