import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faInfoCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const BetCard = ({ bet }) => {
    return (
        <div className="bet-container">
            <div className="event-name">{bet.eventDescription}</div>
            <div className="bet-details">
                <span className="price">Odds: {bet.price.american} ({bet.price.fractional})</span>
                <span className="risk-win">Risk: ${bet.risk} | Win: ${bet.win}</span>
            </div>
        </div>
    );
}

const OpenBets = ({ onClose, showOpenBets }) => {
    const [userBets, setUserBets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [totalStaked, setTotalStaked] = useState(0);
    const [totalWinnings, setTotalWinnings] = useState(0);
    const [totalBets, setTotalBets] = useState(0);

    const [allTimeWagered, setAllTimeWagered] = useState(0);
    const [allTimeProfit, setAllTimeProfit] = useState(0);
    const [allTimeBets, setAllTimeBets] = useState(0);

    const [selectedBetCount, setSelectedBetCount] = useState(5);

    const { isLoaded, isSignedIn, user } = useUser();
    const { getToken } = useAuth();

    useEffect(() => {
        const fetchBets = async () => {
            try {
                const response = await fetch('https://api.moneylinez.xyz/get-user-bets', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await getToken()}`
                    },
                });
                const data = await response.json();
                setUserBets(data || []);

                const totalBets = data.filter(bet => bet.status === 'pending').length;

                const totalStaked = data
                .filter(bet => bet.status === 'pending')
                .reduce((acc, curr) => acc + parseFloat(curr.risk), 0);
                
                const totalWinnings = data
                .filter(bet => bet.status === 'pending')
                .reduce((acc, curr) => acc + parseFloat(curr.win), 0);

                setTotalBets(totalBets);
                setTotalStaked(totalStaked.toFixed(2));
                setTotalWinnings(totalWinnings.toFixed(2));

                const bets = data.filter(bet => bet.status !== 'pending').length;

                const winnings = data
                  .filter(bet => bet.status === 'won')
                  .reduce((sum, bet) => sum + parseFloat(bet.win) + parseFloat(bet.risk), 0);

                const wagered = data
                  .filter(bet => bet.status === 'won' || bet.status === 'lost')
                  .reduce((sum, bet) => sum + parseFloat(bet.risk), 0);
                
                setAllTimeBets(bets);
                setAllTimeWagered(wagered);
                setAllTimeProfit(winnings - wagered);

                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
                toast.error(err.message);
            }
        };

        fetchBets();
    }, []);

    if (loading) {
        return <div></div>;
    }

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    function convertToLocalTime(startTime) {
        const date = new Date(startTime);
        const formattedDate = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
        const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${formattedDate} ${formattedTime}`;
    }

    const handleDropdownChange = (event) => {
        const value = event.target.value;
        setSelectedBetCount(value === 'all' ? userBets.length : parseInt(value));
    };

    return (
      <>
      {showOpenBets && 
      <div className="betslip-overlay" onClick={handleOverlayClick}>

        <ToastContainer
            autoClose={5000}
            hideProgressBar={true}
        />

        <div className="betslip-content">
          {userBets.length === 0 ? (
            <div className="no-bets" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
              <p>No bets in history</p>
              <button className="close-button" style={{ marginTop: '20px' }} onClick={onClose}>Close</button>
            </div>
          ) : (
            <>

            <label>
                Display last 
                <select value={selectedBetCount} onChange={handleDropdownChange} style={{marginLeft: 5, marginRight: 5}}>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="100">100</option>
                  <option value="999999999">All</option>
                </select>
                bets:
            </label>
            <div style={{height: 15}} />

            {userBets.slice(0, selectedBetCount).map((outcome, index) => (
              <div className="bet-item" key={index}>
                <div className="bet-content">
                  <h4 className="game-description">{outcome.eventDescription}</h4>
                  <span className="betslip-time">{convertToLocalTime(outcome.startTime)}</span>

                  <p className="bet-description">
                    {outcome.description} {outcome.price.handicap}
                    <span className="bet-odds">{outcome.price.american}</span>
                  </p>
                  <p className="bet-description">
                    Risk: ${outcome.risk}
                    <span className="bet-odds">To Win: ${outcome.win}</span>
                  </p>
                  <p className="bet-description">
                    Status: <span className="bet-odds">{outcome.status}</span>
                  </p>
                </div>
                
                {outcome.status === 'won' && <FontAwesomeIcon className="status-icon" icon={faCheck} style={{color: 'green'}} />}
                {outcome.status === 'lost' && <FontAwesomeIcon className="status-icon" icon={faTimes} style={{color: 'red'}} />}
                {outcome.status === 'push' && <FontAwesomeIcon className="status-icon" icon={faInfoCircle} style={{color: 'blue'}} />}
                {/* Add similar conditions for 'lost', 'push', and 'pending' here */}
              </div>
            ))}

              <div className="bet-footer">
                <div className="totals">
                  <div>Pending Bets: {totalBets}</div>
                  <div>Pending Wagered: ${totalStaked}</div>
                  <div>Pending Profit: ${totalWinnings}</div>
                  <div style={{height: 20}}></div>
                  <div>All-Time Bets: {Number(allTimeBets)}</div>
                  <div>All-Time Wagered: ${Number(allTimeWagered).toFixed(2)}</div>
                  <div>All-Time Profit: ${Number(allTimeProfit).toFixed(2)}</div>
                </div>
                <button className="close-button" style={{ marginTop: '20px' }} onClick={onClose}>Close</button>
              </div>
            </>
          )}
        </div>
      </div>}
      </>
    );

}

export default OpenBets;
