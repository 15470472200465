import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './PickSheet.css';
import { useAuth, useUser, UserButton } from "@clerk/clerk-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopMenu from './TopMenu';

const PickSheet = () => {
  const location = useLocation();
  const { pool } = location.state;
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const user = useUser();
  const email = user.user.emailAddresses[0].emailAddress;

  const [selectedOutcomes, setSelectedOutcomes] = useState({});
  const [games, setGames] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const selectedGamesSet = new Set(pool.selectedGameIds.map(id => id.toString()));
    const filteredGames = pool.games.filter(game => selectedGamesSet.has(game.id.toString()));
    setGames(filteredGames);
  }, [pool]);

  const handleSelectionChange = (gameId, competitorName) => {
    setSelectedOutcomes({
      ...selectedOutcomes,
      [gameId]: competitorName,
    });
  };

  const handleSubmitPicks = async (event) => {
    event.preventDefault();
    setDisabled(true);

    if (Object.keys(selectedOutcomes).length !== games.length) {
      toast.error("Please make a pick for each game.");
      setDisabled(false);
      return;
    }

    const endpoint = 'https://api.moneylinez.xyz/add-user-picks';
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${await getToken()}`
      },
      body: JSON.stringify({...selectedOutcomes, pool, email})
    });

    if (response.ok) {
      localStorage.setItem('toastMessage', 'Picks successfully recorded!');
      navigate('/pool-home', { state: { pool }});
    } else {
      toast.error('An error occurred while recording picks.');
      setDisabled(false);
    }
  };

  const onClose = () => {
    navigate('/pool-home', { state: { pool }});
  }

  const findGameDate = (gameId) => {
      const game = pool.games.find(game => game.id.toString() === gameId);
      if (game) {
          return formatForDisplay(game.start_date);
      } else {
          console.error(`Game with ID ${gameId} not found`);
          return "Game date not available";
      }
  };

  function formatForDisplay(isoDateString) {
      // Create a new Date object from the ISO string
      const date = new Date(isoDateString);

      // Format the date for display
      // You can adjust the options to get the desired output
      return date.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  }

  const convertToLocalTime = (dateString) => {
      const [date, time] = dateString.split(" ");
      const [year, month, day] = date.split("-");
      const [hours, minutes] = time.split(":");

      const dateObj = new Date(Date.UTC(year, month - 1, day, hours - 1, minutes));
      return dateObj.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div className="pick-sheet-form">
      <ToastContainer autoClose={5000} hideProgressBar={true} />
      <TopMenu />

      <div className="pool-details">
        <h1>{pool.poolName}</h1>
        <p>Pool Admin: {pool.email}</p>
        <p>Sport: {pool.sport.toUpperCase()}</p>
        <p>Type: {pool.poolType.charAt(0).toUpperCase() + pool.poolType.slice(1)}</p>
        <p>Start Date: {formatForDisplay(pool.startDate)}</p>
        <p>End Date: {formatForDisplay(pool.endDate)}</p>
      </div>

      <form onSubmit={handleSubmitPicks}>
        {games.map((game) => (
          <div key={game.id} className="game-item">
            <h2 className="game-title">{convertToLocalTime(game.start_date)}</h2>
            {[...game.participants].reverse().map((competitor) => ( // Reverse the participants array
              <div key={competitor.id} className="pick-option">
                <input 
                  type="radio" 
                  id={`${game.id}-${competitor.id}`} 
                  name={`pick-${game.id}`}
                  value={competitor.name}
                  onChange={() => handleSelectionChange(game.id.toString(), competitor.name)}
                  checked={selectedOutcomes[game.id.toString()] === competitor.name} 
                />
                <label htmlFor={`${game.id}-${competitor.id}`}>{competitor.name}</label>
              </div>
            ))}
          </div>
        ))}
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <button disabled={disabled} type="submit" className="submit-button">Submit Picks</button>
          <button className="submit-button" style={{marginLeft: 10, backgroundColor: '#333333'}} onClick={onClose}>Close</button>
        </div>
      </form>
    </div>
  );
};

export default PickSheet;
