import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";

const AcceptInvite = () => {
  const [searchParams] = useSearchParams();
  const poolId = searchParams.get('poolId');

  const [poolDetails, setPoolDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const [isInPool, setIsInPool] = useState(false);

  const { isLoaded, isSignedIn, user } = useUser();

  useEffect(() => {
    const fetchPoolDetails = async () => {
      setIsLoading(true);
      try {
        const response = await fetch('https://api.moneylinez.xyz/get-pool', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${await getToken()}`
          },
          body: JSON.stringify({ poolId })
        });
        const data = await response.json();
        setPoolDetails(data ? data.pool : null);
      } catch (error) {
        toast.error("Error fetching pool details.");
        console.error("Error fetching pool details: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPoolDetails();
  }, [poolId]);

  useEffect(() => {
    const fetchPools = async () => {
        try {
            const response = await fetch('https://api.moneylinez.xyz/get-user-pools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getToken()}`
                },
            });
            const data = await response.json();
            const filtered = data.filter(pool => poolId === pool.poolId);
            setIsInPool(filtered.length > 0);
        } catch (err) {
            console.error(err.message);
        }
    };

    fetchPools();
  }, [poolId]);

  const backToPool = async () => {
    navigate('/pool-home', { state: { pool: poolDetails }});
  }

  const backToHome = async () => {
    navigate('/');
  }

  const handleAcceptInvite = async () => {
    try {
      const response = await fetch(`https://api.moneylinez.xyz/accept-invite`, {
        method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${await getToken()}`
          },
          body: JSON.stringify({ poolId })
      });
      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('toastMessage', 'Successfully joined pool!');
      } else {
        localStorage.setItem('toastErrorMessage', 'Something went wrong, could not join pool');
      }

      navigate('/pool-home', { state: { pool: poolDetails }});
    } catch (error) {
      toast.error("Error accepting invite.");
      console.error("Error accepting invite: ", error);
    }
  };

  function determinePoolStatus(poolData) {
      const startDate = new Date(poolData.startDate);
      const endDate = new Date(poolData.endDate);
      const now = new Date(); // This will automatically get the current local date and time

      let status;
      if (now < startDate) {
          status = "Not Started";
      } else if (now >= startDate && now <= endDate) {
          status = "Started";
      } else {
          status = "Ended";
      }

      return status;
  }

  const getLocalDate = () => {
      const now = new Date();
      return now.toLocaleDateString('en-CA'); // 'en-CA' format (YYYY-MM-DD) ensures consistency with ISO date format
  };

  function formatForDisplay(isoDateString) {
      // Create a new Date object from the ISO string
      const date = new Date(isoDateString);

      // Format the date for display
      // You can adjust the options to get the desired output
      return date.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  }

  return (
    <div>
      <ToastContainer autoClose={5000} hideProgressBar={true} />
      {isLoading ? (
        <p style={{marginLeft: 10}}>Loading pool details...</p>
      ) : (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingTop: 25}}>
          <h1 style={{maxWidth: '90%'}}>{poolDetails.poolName}</h1>
          <p>Admin: {poolDetails.email}</p>
          <p>Sport: {poolDetails.sport.toUpperCase()}</p>
          <p>Type: {poolDetails.poolType}</p>
          <p>Start Date: {formatForDisplay(poolDetails.startDate)}</p>
          <p>End Date: {formatForDisplay(poolDetails.endDate)}</p>
          {determinePoolStatus(poolDetails) !== "Not Started" ? (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <h4>This pool has already started</h4>
              <button className="submit-button" style={{marginTop: 25}} onClick={backToHome}>Home</button>
            </div>
          ) : (
            <>
              {!isInPool && <button className="submit-button" style={{marginTop: 25}} onClick={handleAcceptInvite}>Accept Invite</button>}
              {isInPool && 
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <h4>You are already in this pool</h4>
                  <button className="submit-button" style={{marginTop: 25}} onClick={backToPool}>Back to pool</button>
                </div>
              }
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AcceptInvite;
