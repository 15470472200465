import { 
  createBrowserRouter, 
  createRoutesFromElements, 
  Route, 
  RouterProvider 
} from 'react-router-dom'

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
} from "@clerk/clerk-react";


import Home from './Home'
import PickSheet from './PickSheet'
import PoolHome from './PoolHome'
import AcceptInvite from './AcceptInvite'
import SportsBook from './SportsBook'

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// router and routes
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<Home />} />
      <Route path="/picksheet" element={
        <>
          <SignedIn>
            <PickSheet />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </>
        } />
      <Route path="/pool-home" element={
        <>
          <SignedIn>
            <PoolHome />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </>
        } />
      <Route path="/accept-invite" element={
        <>
          <SignedIn>
            <AcceptInvite />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </>
        } />
      <Route path="/sportsbook" element={
        <>
          <SignedIn>
            <SportsBook />
          </SignedIn>
          <SignedOut>
            <RedirectToSignIn />
          </SignedOut>
        </>
        } />
    </Route>
  )
)


function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <RouterProvider router={router} />
    </ClerkProvider>
  )
}

export default App
