import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";

const Deposit = ({ onClose, onDepositSuccess, currentBalance, setCurrentBalance }) => {
  const [deposit, setDeposit] = useState(0);

  const { isLoaded, isSignedIn, user } = useUser();
  const { getToken } = useAuth();

  const handleInputChange = (value) => {
      if (!/^\d*(\.\d{0,2})?$/.test(value)) {
          return;
      }

      setDeposit(value);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async () => {
      try {

          if (Number(deposit) > 10000) {
              toast.error("Cannot deposit more than $10,000");
              return;
          }

          const response = await fetch('https://api.moneylinez.xyz/update-user-balance', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${await getToken()}`
              },
              body: JSON.stringify({
                  amount: Number(deposit).toFixed(2),
                  updatedAt: Math.floor(Date.now()),
                  isDeposit: true
              })
          });
          
          const responseData = await response.json();

          if (response.status === 200) {
              setCurrentBalance(prevBalance => prevBalance + parseFloat(deposit)); // Assuming your API doesn't send the updated balance.
              toast.success("Deposit successful!");
              onDepositSuccess();  // Trigger balance update when deposit is successful
          } else {
              toast.error(responseData.message || "An error occurred. Please try again later.");
          }
      } catch (error) {
          toast.error("An error occurred. Please try again later.");
      }
  };

  return (
    <div className="betslip-overlay" onClick={handleOverlayClick}>

      <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
      />

      <div className="betslip-content">
          <>

            <div className="deposit-item">
              <h4>
                Deposit
              </h4>
              <input
                type="text"
                placeholder="$0.00"
                value={deposit || ''}
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </div>

            <div className="bet-footer">
              <div className="totals" style={{display: 'flex', alignItems: 'center'}}>
                <div>Current Balance: ${Number(currentBalance).toFixed(2)}</div>
              </div>
              <button className="submit-button" style={{marginRight: 15, width: 90}} onClick={handleSubmit}>Submit</button>
              <button className="close-button" style={{marginLeft: 15, width: 90}}  onClick={onClose}>Close</button>
            </div>
          </>
      </div>
    </div>
  );

};

export default Deposit;
