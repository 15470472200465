import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";
import { useNavigate } from 'react-router-dom';

const BetSlip = ({ onClose, outcomes, onRemoveBet, currentSport, onClearAllBets, 
                  riskValues, setRiskValues, winValues, setWinValues, onPlaceBetSuccess }) => {

  const [totalRisk, setTotalRisk] = useState(0);
  const [totalWinnings, setTotalWinnings] = useState(0);
  const [focusedField, setFocusedField] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(0);

  const { isLoaded, isSignedIn, user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const totalRiskValue = riskValues.reduce((acc, curr) => acc + Number(curr || 0), 0);
    const totalWinValue = winValues.reduce((acc, curr) => acc + Number(curr || 0), 0);

    setTotalRisk(totalRiskValue);
    setTotalWinnings(totalWinValue);
  }, [riskValues, winValues]);

  const handleRemoveFromBetSlip = (indexToRemove) => {
    // Inform the parent component to remove the bet from outcomes
    onRemoveBet(indexToRemove);

    // Remove the corresponding risk and win values locally
    setRiskValues(prevRiskValues => prevRiskValues.filter((_, index) => index !== indexToRemove));
    setWinValues(prevWinValues => prevWinValues.filter((_, index) => index !== indexToRemove));
  };

  useEffect(() => {
      const fetchBalance = async () => {
          try {
              const response = await fetch('https://api.moneylinez.xyz/get-user-balance', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${await getToken()}`
                  },
              });
              const data = await response.json();
              setCurrentBalance(data[0].balance || 0);
          } catch (err) {
              console.error(err.message);
          }
      };

      fetchBalance();

      const cleanedRiskValues = riskValues.filter(item => item !== "");
      const cleanedWinValues = winValues.filter(item => item !== "");

      setRiskValues(cleanedRiskValues);
      setWinValues(cleanedWinValues);

  }, []);

  useEffect(() => {
      // If a bet was added
      if (outcomes.length > riskValues.length) {
          setRiskValues(prevRiskValues => [...prevRiskValues, '']);
          setWinValues(prevWinValues => [...prevWinValues, '']);
      }
      // If a bet was removed
      else if (outcomes.length < riskValues.length) {
          setRiskValues(prevRiskValues => {
              const updatedRiskValues = [...prevRiskValues];
              updatedRiskValues.pop();
              return updatedRiskValues;
          });
          setWinValues(prevWinValues => {
              const updatedWinValues = [...prevWinValues];
              updatedWinValues.pop();
              return updatedWinValues;
          });
      }
  }, [outcomes]);

  const handleInputChange = (index, type, value) => {
    if (!/^\d*(\.\d{0,2})?$/.test(value)) {
        return;
    }

    const updatedRiskValues = [...riskValues];
    const updatedWinValues = [...winValues];
    let americanOdds = outcomes[index].price.american;

    if (americanOdds === 'EVEN') {
      americanOdds = 100;
    }

    if (type === 'risk') {
      updatedRiskValues[index] = value;
      if (!isNaN(value) && value.trim() !== '') {
        if (americanOdds > 0) {
          updatedWinValues[index] = (value * (americanOdds / 100)).toFixed(2);
        } else {
          updatedWinValues[index] = (value / (Math.abs(americanOdds) / 100)).toFixed(2);
        }
      } else {
        updatedWinValues[index] = '';
      }
    } else if (type === 'win') {
      updatedWinValues[index] = value;
      if (!isNaN(value) && value.trim() !== '') {
        if (americanOdds > 0) {
          updatedRiskValues[index] = (value / (americanOdds / 100)).toFixed(2);
        } else {
          updatedRiskValues[index] = (value * (Math.abs(americanOdds) / 100)).toFixed(2);
        }
      } else {
        updatedRiskValues[index] = '';
      }
    }

    setRiskValues(updatedRiskValues);
    setWinValues(updatedWinValues);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async () => {
      try {
          // Validate risk and win values
          const hasInvalidValue = riskValues.some(value => value === 0 || value === "" || value === undefined) 
                              || winValues.some(value => value === 0 || value === "" || value === undefined);

          if (hasInvalidValue) {
              toast.error("Risk value cannot be $0");
              return; // Exit the function if any value is invalid
          }

          // Check balance
          const totalRisked = riskValues.reduce((acc, value) => acc + Number(value), 0);
          const hasEnoughBalance = currentBalance >= totalRisked;

          if (!hasEnoughBalance) {
              toast.error("Insufficient balance");
              return;
          }

          // Check for maximum bet limit
          const maxBetExceeded = riskValues.some(value => value > 10000) 
                              || winValues.some(value => value > 10000);

          if (maxBetExceeded) {
              toast.error("Risk and win values must be less than $10,000");
              return;
          }

          // Prepare the bet payload
          const betPayload = outcomes.map((outcome, index) => ({
              gameId: outcome.id, // Game ID
              description: outcome.description,
              type: outcome.type,
              risk: Number(riskValues[index]), // Risk value for the bet
              sport: currentSport // Current sport
          }));

          // Define the API endpoint
          const endpoint = 'https://api.moneylinez.xyz/add-user-bets';

          // Make the API request
          const response = await fetch(endpoint, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${await getToken()}`
              },
              body: JSON.stringify(betPayload)
          });

          const data = await response.json();

          // Handle the response
          if (response.status === 200) {
              // Clear the bet slip
              onClearAllBets();
              setRiskValues([]);  // Clear risk values
              setWinValues([]);  // Clear win values

              // Update the current balance
              setCurrentBalance(currentBalance - totalRisked);
              onPlaceBetSuccess();

              if (betPayload.length > 1) {
                localStorage.setItem('toastMessage', 'Bets successfully placed!');
              } else {
                localStorage.setItem('toastMessage', 'Bet successfully placed!');
              }
              onClose();
              navigate('/sportsbook', { state: { refresh: Date.now() } });
          } else {
              toast.error("Risk value cannot be $0");
          }
      } catch (error) {
          toast.error("An error occurred. Please try again later.");
      }
  };

  function convertToLocalTime(startTime) {
    const date = new Date(startTime);
    const formattedDate = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit' });
    const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  }

  return (
    <div className="betslip-overlay" onClick={handleOverlayClick}>

      <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
      />

      <div className="betslip-content">
        {outcomes.length === 0 ? (
          <div className="no-bets" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <p>No bets in bet slip</p>
            <button className="close-button" style={{ marginTop: '20px' }} onClick={onClose}>Close</button>
          </div>
        ) : (
          <>
            {outcomes.map((outcome, index) => (
              <div className="bet-item" key={index}>

                <h4 className="game-description">{outcome.eventDescription}</h4>
                <span className="betslip-time">{convertToLocalTime(outcome.startTime)}</span>

                <p className="bet-description">
                  {outcome.description} {outcome.price.handicap}
                  <span className="bet-odds">{outcome.price.american}</span>
                  <button className="remove-bet" onClick={() => handleRemoveFromBetSlip(index)}>
                    x
                  </button>
                </p>
                <div className="risk-win-inputs">
                  <input
                    type="text"
                    placeholder="Risk"
                    value={riskValues[index] || ''}
                    onFocus={() => setFocusedField('risk' + index)}
                    onBlur={() => setFocusedField(null)}
                    onChange={(e) => handleInputChange(index, 'risk', e.target.value)}
                  />
                  <div style={{ width: 10 }} />
                  <input
                    type="text"
                    placeholder="Win"
                    value={winValues[index] || ''}
                    onFocus={() => setFocusedField('win' + index)}
                    onBlur={() => setFocusedField(null)}
                    onChange={(e) => handleInputChange(index, 'win', e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className="bet-footer">
              <div className="totals">
                <div>Total Bets: {outcomes.length}</div>
                <div>Total Stake: ${totalRisk.toFixed(2)}</div>
                <div>Possible Winnings: ${totalWinnings.toFixed(2)}</div>
                <div>Current Balance: ${currentBalance.toFixed(2)}</div>
              </div>
              <button className="submit-button" style={{marginRight: 15, width: 90}} onClick={handleSubmit}>Submit</button>
              <button className="close-button" style={{marginLeft: 15, width: 90}}  onClick={onClose}>Close</button>
            </div>
          </>
        )}
      </div>
    </div>
  );

};

export default BetSlip;
