import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';

const CreatePool = ({ onClose }) => {
  const [poolName, setPoolName] = useState('');
  const [sport, setSport] = useState('nfl');
  const [poolType, setPoolType] = useState('pick-em');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { isLoaded, isSignedIn, user } = useUser();
  const email = user.emailAddresses[0].emailAddress;

  const { getToken } = useAuth();

  const [games, setGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState({});

  const [selectAll, setSelectAll] = useState(false);
  const [groupedGames, setGroupedGames] = useState({});

  const fetchGames = async (startDate, endDate, sport) => {
    try {
      setLoading(true);
      setDisabled(true);

      const token = await getToken(); // Make sure to retrieve the auth token

      // Parse endDate and add one day
      const endDateObject = new Date(endDate);

      endDateObject.setDate(endDateObject.getDate() + 1);

      // Format endDate to YYYY-MM-DD
      const adjustedEndDate = endDateObject.toISOString().split('T')[0];

      const response = await fetch('https://api.moneylinez.xyz/get-pool-games', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the token in the Authorization header
        },
        body: JSON.stringify({ startDate, endDate: adjustedEndDate, sport })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const upcomingGames = data.filter((game) => {
        return game.status_type === "scheduled"
      });

      setGames(upcomingGames);

      setLoading(false);
      setDisabled(false);

    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error('Failed to fetch games. Please try again.');

      setLoading(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (sport && startDate && endDate) {
      try {
        fetchGames(startDate, endDate, sport);
      } catch (error) {
        console.error(error);
      }
    }
  }, [sport, startDate, endDate]);

  useEffect(() => {
    if (poolType === 'sportsbook') {
      // Auto-select all games and disable checkboxes
      const autoSelectedGames = {};
      games.forEach((game) => {
        autoSelectedGames[game.id] = true;
      });
      setSelectedGames(autoSelectedGames);
      setSelectAll(true); // Set select all to true
    } else {
      // Reset selection if pool type changes
      setSelectedGames({});
      setSelectAll(false);
    }
  }, [poolType, games]);

  useEffect(() => {
    // Convert startDate and endDate to moment objects
    const startDateMoment = moment(startDate + 'T00:00:00');
    const endDateMoment = moment(endDate + 'T23:59:59');

    // Group games by date, excluding games outside the start and end dates
    const gamesByDate = games.reduce((acc, game) => {
      // Parse game start date as moment object in GMT+1
      const gameDateMoment = moment(game.start_date + ' +0100', "YYYY-MM-DD HH:mm Z");

      // Convert game start date to local date string
      const localDate = gameDateMoment.format('MM/DD/YYYY');

      // Check if the game date is within the start and end date range
      if (gameDateMoment.isSameOrAfter(startDateMoment) && gameDateMoment.isSameOrBefore(endDateMoment)) {
        if (!acc[localDate]) {
          acc[localDate] = [];
        }
        acc[localDate].push(game);
      }

      return acc;
    }, {});

    setGroupedGames(gamesByDate);

    // Check if all games are selected
    const allSelected = games.every((game) => selectedGames[game.id]);
    setSelectAll(allSelected);

  }, [games, selectedGames, startDate, endDate]);

  const handleGameSelection = (gameId) => {
    const game = games.find(game => game.id === gameId);
    const gameStartDate = new Date(game.start_date);
    const now = new Date();

    if (gameStartDate > now) {
      setSelectedGames({
        ...selectedGames,
        [gameId]: !selectedGames[gameId],
      });
    }
  };

  const handleSelectAllGames = (selectAll) => {
    // Update the select all state
    setSelectAll(selectAll);

    // Update the selectedGames state based on the new select all state
    const newSelectedGames = {};
    games.forEach((game) => {
      newSelectedGames[game.id] = selectAll;
    });

    setSelectedGames(newSelectedGames);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  function findDateIndices(arr) {
      // Adding original index to each object
      const arrWithIndex = arr.map((item, index) => ({ ...item, originalIndex: index }));

      // Sorting based on start_date
      arrWithIndex.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));

      // Getting the indices of the earliest and latest dates
      const earliestDateIndex = arrWithIndex[0].originalIndex;
      const latestDateIndex = arrWithIndex[arrWithIndex.length - 1].originalIndex;

      return { earliestDateIndex, latestDateIndex };
  }

  function convertToUTCAndFormat(dateString, isStartDate) {
      // Replace space with 'T', add seconds ':00', and timezone offset for UTC+1
      const dateStringWithTimezone = dateString.replace(' ', 'T') + ':00+01:00';

      // Create a Date object in UTC+1
      const dateInUTCPlusOne = new Date(dateStringWithTimezone);

      // Convert to UTC by subtracting 1 hour
      if (isStartDate) {
        dateInUTCPlusOne.setHours(dateInUTCPlusOne.getHours());
      } else {
        dateInUTCPlusOne.setHours(dateInUTCPlusOne.getHours() + 4);
      }

      // Convert to ISO 8601 format (YYYY-MM-DDTHH:MM:SS.sssZ)
      return dateInUTCPlusOne.toISOString();
  }

  const getLocalDateString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);

    // Flatten groupedGames into a single array
    const flattenedGroupedGames = Object.values(groupedGames).flat();
    const isAnyGameSelected = flattenedGroupedGames.some(game => selectedGames[game.id]);

    if (!poolName || !sport || !poolType || !isAnyGameSelected || !startDate || !endDate) {
      toast.error('Please fill in all fields and select at least 1 game to create a pool.');
      setDisabled(false);
      return;
    }

    const selectedGameIds = flattenedGroupedGames.filter(game => selectedGames[game.id]).map(game => game.id.toString());

    const { earliestDateIndex, latestDateIndex } = findDateIndices(flattenedGroupedGames);

    const _startDate = convertToUTCAndFormat(flattenedGroupedGames[earliestDateIndex].start_date, true);
    const _endDate = convertToUTCAndFormat(flattenedGroupedGames[latestDateIndex].start_date, false);

    try {
        const response = await fetch('https://api.moneylinez.xyz/create-pool', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getToken()}`
            },
            body: JSON.stringify({
                poolName,
                sport,
                poolType,
                selectedGameIds,
                games: flattenedGroupedGames,
                startDate: _startDate,
                endDate: _endDate,
                email
            })
        });
        
        const responseData = await response.json();

        if (response.status === 200) {
            toast.success("Pool successfully created!");
            setDisabled(false);

            localStorage.setItem('toastMessage', 'Pool successfully created!');
            navigate('/pool-home', { state: { pool: responseData.pool }});
            onClose();
        } else {
            toast.error(responseData.message || "An error occurred. Please try again later.");
            setDisabled(false);
        }
    } catch (error) {
        toast.error("An error occurred. Please try again later.");
        setDisabled(false);
    }
  };

  return (
    <div className="betslip-overlay" onClick={handleOverlayClick}>

      <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
      />

      <div className="betslip-content">
          <>

            <div className="deposit-item">
              <h4>
                Create Pool
              </h4>

              <div>Pool Name</div>
              <input
                type="text"
                value={poolName}
                onChange={(e) => setPoolName(e.target.value)}
              />
            
              <div style={{paddingTop: 15}}>Sport</div>
              <select
                placeholder="Select sport"
                value={sport}
                onChange={(e) => setSport(e.target.value)}
              >
                <option value="nfl">NFL</option>
                <option value="ncaaf">NCAAF</option>
                <option value="nba">NBA</option>
                <option value="ncaam">NCAAM</option>
                <option value="mlb">MLB</option>
                <option value="nhl">NHL</option>
              </select>
            
              <div style={{paddingTop: 15}}>Pool Type</div>
              <select
                placeholder="Select pool type"
                value={poolType}
                onChange={(e) => setPoolType(e.target.value)}
              >
                <option value="pick-em">Pick 'Em</option>
              </select>
            
              <div style={{paddingTop: 15}}>Start Date</div>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min={getLocalDateString()} // Sets the minimum date to today
              />
            
              <div style={{paddingTop: 15}}>End Date</div>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate ? startDate : getLocalDateString()}
              />

              {loading && <div className="loader"></div>} {/* Show spinner when loading */}

              {games.length === 0 && !loading && <div style={{paddingTop: 30}}>
                  <p>No games in selected date range</p>
                </div>}

              {games.length > 0 && !loading && <div style={{paddingTop: 30}}>
                {poolType !== 'sportsbook' && (
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={(e) => handleSelectAllGames(e.target.checked)}
                        disabled={poolType === 'sportsbook'} // Disable if pool type is 'sportsbook'
                      />
                      Select All Games
                    </label>
                  </div>
                )}
                {Object.entries(groupedGames).map(([date, gamesForDate]) => (
                  <div key={date}>
                    <p style={{ fontWeight: 'bold' }}>{date}</p>
                    {gamesForDate.map((game) => (
                      <div key={game.id}>
                        <label>
                          <input
                            type="checkbox"
                            checked={selectedGames[game.id]}
                            onChange={() => handleGameSelection(game.id)}
                            disabled={new Date(game.start_date) <= new Date() || poolType === 'sportsbook'} // Disable if game has started or pool type is 'sportsbook'
                          />
                          {game.name}
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
              </div>}

              <div
                style={{display: 'flex', flexDirection: 'row'}}
              >
                <button 
                  className="submit-button" 
                  style={{marginTop: 45}}
                  onClick={handleSubmit} 
                  colorScheme="blue"
                  disabled={disabled}
                >
                  Create Pool
                </button>

                <button 
                  className="close-button" 
                  style={{marginTop: 45, marginLeft: 10, width: 90}}
                  onClick={onClose}
                  disabled={disabled}
                >
                  Close
                </button>
              </div>

            </div>
          </>
      </div>
    </div>
  );

};

export default CreatePool;
