import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';

import CreatePool from './CreatePool';
import MyPools from './MyPools';

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
  useAuth,
} from "@clerk/clerk-react";

const TopMenu = () => {

  const [showCreatePool, setShowCreatePool] = useState(false);
  const [showMyPools, setShowMyPools] = useState(false);
  const { getToken } = useAuth();
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  const openComponent = (component) => {
      setShowCreatePool(false);
      setShowMyPools(false);

      switch (component) {
          case 'createPool':
              setShowCreatePool(true);
              break;
          case 'myPools':
              setShowMyPools(true);
              break;
          case 'home':
              navigate(`/`);
              break;
          default:
              break;
      }
  };

  useEffect(() => {
      const fetchPools = async () => {
          try {
              const response = await fetch('https://api.moneylinez.xyz/get-user-pools', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${await getToken()}`
                  },
              });
              const data = await response.json();
              setPools(data);
          } catch (err) {
              console.error(err.message);
          }
      };

      fetchPools();
  }, []);

  return (
    <div style={{ position: 'fixed', right: 20, top: 20, display: 'flex', alignItems: 'center', gap: '15px' }}>
        <UserButton />
        <Menu>
            <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Menu
            </MenuButton>
            <MenuList
                minWidth="150px"
            >
                <MenuItem
                    _hover={{ bg: "gray.600", color: "white" }}
                    sx={{
                        backgroundColor: "white",
                        borderColor: "gray",
                        borderWidth: 1,
                        borderRadius: 1,
                        '.chakra-menu__menuitem': {
                            color: 'white',
                        }
                    }}
                    onClick={() => openComponent('home')}
                >
                    Home
                </MenuItem>
                <MenuItem
                    _hover={{ bg: "gray.600", color: "white" }}
                    sx={{
                        backgroundColor: "white",
                        borderColor: "gray",
                        borderWidth: 1,
                        borderRadius: 1,
                        '.chakra-menu__menuitem': {
                            color: 'white',
                        }
                    }}
                    onClick={() => openComponent('createPool')}
                >
                    Create Pool
                </MenuItem>
                <MenuItem
                    _hover={{ bg: "gray.600", color: "white" }}
                    sx={{
                        backgroundColor: "white",
                        borderColor: "gray",
                        borderWidth: 1,
                        borderRadius: 1,
                        '.chakra-menu__menuitem': {
                            color: 'white',
                        }
                    }}
                    onClick={() => openComponent('myPools')}
                >
                    My Pools
                </MenuItem>
            </MenuList>

            {showCreatePool && 
              <>
                <SignedIn>
                    <CreatePool 
                      onClose={() => setShowCreatePool(false)} 
                    />
                </SignedIn>
                <SignedOut>
                    <RedirectToSignIn />
                </SignedOut>
              </>
            }
            {showMyPools && 
              <>
                <SignedIn>
                    <MyPools 
                      onClose={() => setShowMyPools(false)}
                      showMyPools={showMyPools}
                      pools={pools}
                      setPools={setPools}
                    />
                </SignedIn>
                <SignedOut>
                    <RedirectToSignIn />
                </SignedOut>
              </>
          }

        </Menu>
    </div>
  );
};

export default TopMenu;
