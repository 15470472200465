import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
  useAuth,
} from "@clerk/clerk-react";

import nflMockData from './mock_data/nfl.json';
import ncaafMockData from './mock_data/ncaaf.json';
import nbaMockData from './mock_data/nba.json';
import ncaamMockData from './mock_data/ncaam.json';
import mlbMockData from './mock_data/mlb.json';
import nhlMockData from './mock_data/nhl.json';

import BetSlip from './BetSlip';
import OpenBets from './OpenBets';
import Deposit from './Deposit';
import Balance from './Balance';
import CreatePool from './CreatePool';
import MyPools from './MyPools';

import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { ChevronDownIcon } from '@chakra-ui/icons'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SportsBook = () => {
    const { getToken } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [betSlip, setBetSlip] = useState([]);
    const [showBetSlip, setShowBetSlip] = useState(false);
    const [showOpenBets, setShowOpenBets] = useState(false);
    const [showDeposit, setShowDeposit] = useState(false);
    const [showCreatePool, setShowCreatePool] = useState(false);
    const [showMyPools, setShowMyPools] = useState(false);

    const [pools, setPools] = useState([]);

    const [currentSport, setCurrentSport] = useState('nfl');
    const [riskValues, setRiskValues] = useState([]);
    const [winValues, setWinValues] = useState([]);

    const [balanceUpdateTrigger, setBalanceUpdateTrigger] = useState(0);  // State to trigger balance update
    const [currentBalance, setCurrentBalance] = useState(0);

    const sportsMapping = {
        nfl: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/football/nfl?marketFilterId=def&preMatchOnly=true&eventsLimit=50&lang=en',
        ncaaf: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/football/college-football?marketFilterId=def&preMatchOnly=true&eventsLimit=5000&lang=en',
        nba: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/basketball?marketFilterId=def&preMatchOnly=true&eventsLimit=50&lang=en',
        ncaam: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/basketball/college-basketball?marketFilterId=def&preMatchOnly=true&eventsLimit=50&lang=en',
        mlb: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/baseball?marketFilterId=def&preMatchOnly=true&eventsLimit=50&lang=en',
        nhl: 'https://www.bovada.lv/services/sports/event/coupon/events/A/description/hockey?marketFilterId=def&preMatchOnly=true&eventsLimit=50&lang=en',
    };

    useEffect(() => {
        const fetchData = async (url) => {
              try {
                  const response = await fetch('https://api.moneylinez.xyz/get-odds', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${await getToken()}`
                      },
                      body: JSON.stringify({sport: currentSport}) 
                  });
                  const data = await response.json();
                  setData(data);
                  setLoading(false);
              } catch (err) {
                  console.error(err.message);
                  setLoading(false);
              }
        };

        fetchData(sportsMapping[currentSport]);
    }, [currentSport]);

      useEffect(() => {
        const toastMessage = localStorage.getItem('toastMessage');

        if (toastMessage) {
          toast.success(toastMessage);
          localStorage.removeItem('toastMessage'); // Clear the message
        }
      }, [location.state?.refresh]);

      useEffect(() => {
          const fetchPools = async () => {
              try {
                  const response = await fetch('https://api.moneylinez.xyz/get-user-pools', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'Authorization': `Bearer ${await getToken()}`
                      },
                  });
                  const data = await response.json();
                  setPools(data);
              } catch (err) {
                  console.error(err.message);
              }
          };

          fetchPools();
      }, []);

    function convertToLocalTime(startTime) {
        const date = new Date(startTime);
        const formattedDate = date.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
        const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
        return `${formattedDate} ${formattedTime}`;
    }

    const prependPlus = (number) => {
        if (number > 0) return `+${number}`;
        return number.toString();
    };

    const openComponent = (component) => {
        setShowBetSlip(false);
        setShowOpenBets(false);
        setShowDeposit(false);
        setShowCreatePool(false);
        setShowMyPools(false);

        switch (component) {
            case 'betSlip':
                setShowBetSlip(true);
                break;
            case 'openBets':
                setShowOpenBets(true);
                break;
            case 'deposit':
                setShowDeposit(true);
                break;
            case 'createPool':
                setShowCreatePool(true);
                break;
            case 'myPools':
                setShowMyPools(true);
                break;
            case 'home':
                navigate(`/`);
                break;
            default:
                break;
        }
    };

    const handleBetClick = (gameId, outcome, eventDescription, startTime) => {
        // Include eventDescription and startTime in the outcome object
        const updatedOutcome = {
            ...outcome,
            eventDescription,
            startTime
        };
        updatedOutcome.id = gameId;

        setBetSlip([...betSlip, updatedOutcome]);
        setShowBetSlip(true);
    };

    if (loading) {
        return <div style={{marginLeft: 10, marginTop: 10}}>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const handleRemoveBet = (indexToRemove) => {
        const updatedBetSlip = betSlip.filter((_, index) => index !== indexToRemove);
        setBetSlip(updatedBetSlip);
    };

    const handleClearAllBets = () => {
        setBetSlip([]);
    };

    return (
        <>
            <ToastContainer autoClose={5000} hideProgressBar={true} />
            <div className="Logo">
                <img src={logo} alt="Logo" style={{ width: '290px', height: '150px' }} />
            </div>

            <div style={{ position: 'fixed', left: 25, top: 25 }}>
                <SignedIn>
                    <Balance 
                        updateTrigger={balanceUpdateTrigger} 
                        currentBalance={currentBalance}
                        setCurrentBalance={setCurrentBalance}
                    />
                </SignedIn>
            </div>
            <div className="App">

                {currentSport === 'nfl' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>NFL</h1>}
                {currentSport === 'ncaaf' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>NCAAF</h1>}
                {currentSport === 'nba' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>NBA</h1>}
                {currentSport === 'ncaam' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>NCAAM</h1>}
                {currentSport === 'mlb' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>MLB</h1>}
                {currentSport === 'nhl' && <h1 style={{textAlign: 'center', paddingBottom: 20}}>NHL</h1>}

                <div style={{ position: 'fixed', right: 20, top: 20, display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <UserButton />
                    <Menu>
                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                            Menu
                        </MenuButton>
                        <MenuList
                            minWidth="150px"
                        >
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }}
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('home')}
                            >
                                Home
                            </MenuItem>
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }} // Changes background and text color on hover
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('deposit')}
                            >
                                Deposit
                            </MenuItem>
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }}
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('betSlip')}
                            >
                                Bet Slip
                            </MenuItem>
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }}
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('openBets')}
                            >
                                Bet History
                            </MenuItem>
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }}
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('createPool')}
                            >
                                Create Pool
                            </MenuItem>
                            <MenuItem
                                _hover={{ bg: "gray.600", color: "white" }}
                                sx={{
                                    backgroundColor: "white",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    borderRadius: 1,
                                    '.chakra-menu__menuitem': {
                                        color: 'white',
                                    }
                                }}
                                onClick={() => openComponent('myPools')}
                            >
                                My Pools
                            </MenuItem>
                        </MenuList>

                    </Menu>
                </div>

                {/* Sports navigation */}
                <div className="sports-nav">
                  {Object.keys(sportsMapping).map((sport) => (
                    <button 
                      key={sport} 
                      onClick={() => setCurrentSport(sport)}
                      className={currentSport === sport ? 'active' : ''}
                    >
                      {sport.toUpperCase()}
                    </button>
                  ))}
                </div>

                {data.map((event, index) => {
                    const displayGroups = event.displayGroups;
                    if (!displayGroups || displayGroups.length === 0) return null;
                    const markets = displayGroups[0].markets;

                    const gameId = event.id;

                    const possibleDescriptions = ['Point Spread', 'Puck Line', 'Runline'];
                    const spread = markets.find(m => possibleDescriptions.includes(m.description));

                    const moneyline = markets.find(m => m.description === 'Moneyline');
                    const total = markets.find(m => m.description === 'Total');
                    if (!spread || !moneyline || !total) return null;

                    return (
                        <div key={index} className="game">
                            <h2>
                                <div className="clickable">{convertToLocalTime(event.startTime)}</div>
                            </h2>

                            {spread.outcomes && spread.outcomes.length > 1 && (
                                <div className="market">
                                    <h3>Point Spread</h3>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, spread.outcomes[0], event.description, event.startTime)}>
                                        {spread.outcomes[0].description}: {prependPlus(spread.outcomes[0].price.handicap)} ({spread.outcomes[0].price.american})
                                    </div>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, spread.outcomes[1], event.description, event.startTime)}>
                                        {spread.outcomes[1].description}: {prependPlus(spread.outcomes[1].price.handicap)} ({spread.outcomes[1].price.american})
                                    </div>
                                </div>
                            )}

                            {moneyline.outcomes && moneyline.outcomes.length > 1 && (
                                <div className="market">
                                    <h3>Moneyline</h3>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, moneyline.outcomes[0], event.description, event.startTime)}>
                                        {moneyline.outcomes[0].description}: {moneyline.outcomes[0].price.american}
                                    </div>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, moneyline.outcomes[1], event.description, event.startTime)}>
                                        {moneyline.outcomes[1].description}: {moneyline.outcomes[1].price.american}
                                    </div>
                                </div>
                            )}

                            {total.outcomes && total.outcomes.length > 1 && (
                                <div className="market">
                                    <h3>Total (Over/Under)</h3>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, total.outcomes[0], event.description, event.startTime)}>
                                        {total.outcomes[0].description}: {total.outcomes[0].price.handicap} ({total.outcomes[0].price.american})
                                    </div>
                                    <div className="clickable" onClick={() => handleBetClick(gameId, total.outcomes[1], event.description, event.startTime)}>
                                        {total.outcomes[1].description}: {total.outcomes[1].price.handicap} ({total.outcomes[1].price.american})
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}

                {showBetSlip && 
                  <>
                    <SignedIn>
                        <BetSlip 
                          onClose={() => setShowBetSlip(false)} 
                          outcomes={betSlip} 
                          onRemoveBet={handleRemoveBet}
                          currentSport={currentSport}
                          onClearAllBets={handleClearAllBets}
                          riskValues={riskValues}
                          setRiskValues={setRiskValues}
                          winValues={winValues} 
                          setWinValues={setWinValues}
                          onPlaceBetSuccess={() => setBalanceUpdateTrigger(prev => prev + 1)}  // Update the trigger when bet is placed
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
                }
                {showOpenBets && 
                  <>
                    <SignedIn>
                        <OpenBets 
                          onClose={() => setShowOpenBets(false)} 
                          showOpenBets={showOpenBets}
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
                }
                {showDeposit && 
                  <>
                    <SignedIn>
                        <Deposit 
                          onClose={() => setShowDeposit(false)} 
                          onDepositSuccess={() => setBalanceUpdateTrigger(prev => prev + 1)}  // Update the trigger when deposit is successful
                          currentBalance={currentBalance}
                          setCurrentBalance={setCurrentBalance}
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
                }
                {showCreatePool && 
                  <>
                    <SignedIn>
                        <CreatePool 
                          onClose={() => setShowCreatePool(false)} 
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
                }
                {showMyPools && 
                  <>
                    <SignedIn>
                        <MyPools 
                          onClose={() => setShowMyPools(false)}
                          showMyPools={showMyPools}
                          pools={pools}
                          setPools={setPools}
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
              }
            </div>
        </>
    );
}

export default SportsBook;

