import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './logo.png';

import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
  RedirectToSignIn,
  useAuth,
} from "@clerk/clerk-react";

import CreatePool from './CreatePool';
import MyPools from './MyPools';
import { useNavigate } from 'react-router-dom';
import TopMenu from './TopMenu';

const Home = () => {
    const { getToken } = useAuth();
    const navigate = useNavigate();

    const [showCreatePool, setShowCreatePool] = useState(false);
    const [showMyPools, setShowMyPools] = useState(false);
    const [pools, setPools] = useState([]);

    useEffect(() => {
      const fetchPools = async () => {
          try {
              const response = await fetch('https://api.moneylinez.xyz/get-user-pools', {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${await getToken()}`
                  },
              });
              const data = await response.json();
              setPools(data);
          } catch (err) {
              console.error(err.message);
          }
      };

      fetchPools();
    }, []);

    const handleCreatePool = () => {
        setShowCreatePool(true);
    };

    const handleMyPools = () => {
        setShowMyPools(true);
    };

    return (
        <>
            <div className="Logo">
                <img src={logo} alt="Logo" style={{ width: '290px', height: '150px' }} />
            </div>

            <div className="App">
                <TopMenu />

                <div className="home-container">
                  <section className="intro-section">
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <h1>Welcome to MoneyLinez</h1>
                    </div>
                    <p>
                      MoneyLinez is the ultimate destination for sports enthusiasts who love
                      the thrill of competition. Create pick 'em pools with friends for a
                      variety of sports and enjoy the excitement of the game together. It's
                      all about fun, camaraderie, and a little friendly competition.
                    </p>
                  </section>

                  <section className="get-started-section">
                    <div className="get-started-buttons">
                      <button onClick={handleCreatePool} className="get-started-button">
                        Create Pool
                      </button>
                      <button onClick={handleMyPools} className="get-started-button" style={{backgroundColor: '#333333'}}>
                        My Pools
                      </button>
                    </div>
                  </section>
                </div>

                {showCreatePool && 
                  <>
                    <SignedIn>
                        <CreatePool 
                          onClose={() => setShowCreatePool(false)} 
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
                }
                {showMyPools && 
                  <>
                    <SignedIn>
                        <MyPools 
                          onClose={() => setShowMyPools(false)}
                          showMyPools={showMyPools}
                          pools={pools}
                          setPools={setPools}
                        />
                    </SignedIn>
                    <SignedOut>
                        <RedirectToSignIn />
                    </SignedOut>
                  </>
              }
            </div>
        </>
    );
}

export default Home;
