import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './PoolHome.css'; // Adjust the CSS file name as necessary
import { useUser, useAuth, UserButton } from "@clerk/clerk-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopMenu from './TopMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

const PoolHome = () => {
  const location = useLocation();
  const { pool } = location.state;
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const user = useUser();
  const email = user.user.emailAddresses[0].emailAddress;

  const [hasMadePicks, setHasMadePicks] = useState(false);
  const [userPicks, setUserPicks] = useState([]);
  const [standings, setStandings] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [viewedUserPicks, setViewedUserPicks] = useState(email);
  const [poolStatus, setPoolStatus] = useState(null);

  const handleMakePicks = () => {
    navigate('/picksheet', { state: { pool } });
  };

  useEffect(() => {
    const toastMessage = localStorage.getItem('toastMessage');
    const toastErrorMessage = localStorage.getItem('toastErrorMessage');

    if (toastMessage) {
      toast.success(toastMessage);
      localStorage.removeItem('toastMessage'); // Clear the message
    }
    if (toastErrorMessage) {
      toast.error(toastErrorMessage);
      localStorage.removeItem('toastErrorMessage'); // Clear the message
    }
  }, []);

  const fetchPicks = async (viewedEmail) => {
      try {
          const response = await fetch('https://api.moneylinez.xyz/get-user-picks', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getToken()}`
              },
              body: JSON.stringify({poolId: pool.poolId})
          });
          const data = await response.json();

          setIsAdmin(pool.email === email);

          // Check if current user has made picks
          const currentUserPicks = data.find(pick => pick.picks.email === viewedEmail);

          if (currentUserPicks) {
              // Create a map of game IDs to their start dates
              const gameDates = currentUserPicks.picks.pool.games.reduce((acc, game) => {
                acc[game.id] = game.start_date;
                return acc;
              }, {});

              const userPicksWithResults = Object.entries(currentUserPicks.picks).map(([gameId, teamName]) => {
                  // Filter out the 'pool' and 'email' keys from the picks
                  if (gameId !== 'pool' && gameId !== 'email') {
                      const result = currentUserPicks.results?.[gameId] || '';
                      const startDate = gameDates[gameId];
                      return { gameId, teamName, result, startDate };
                  }
                  return null;
              }).filter(pick => pick); // Filter out null entries

              // Sort by game start date
              userPicksWithResults.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));

              // Convert back to the original format
              const sortedUserPicks = userPicksWithResults.map(pick => [pick.gameId, pick.teamName, pick.result]);

              setUserPicks(sortedUserPicks);
          }

          const myPicks = data.find(item => item.picks.email === email);
          if (myPicks) {
            setHasMadePicks(true);
          } else {
            setHasMadePicks(false);
          }

          const standingsData = data.map(userPick => {
            // Count the number of wins and losses in the results object
            const results = userPick.results || {};
            const wins = Object.values(results).filter(result => result === 'win').length;
            const losses = Object.values(results).filter(result => result === 'loss').length;

            return {
              email: userPick.picks.email, // Assuming email is directly on userPick object
              wins: wins,
              losses: losses
            };
          });

          // Sort the standings by number of wins, and then by fewer losses if wins are equal
          standingsData.sort((a, b) => b.wins - a.wins || a.losses - b.losses);

          setStandings(standingsData);

          setViewedUserPicks(viewedEmail);
      } catch (err) {
          console.error(err.message);
      }
  };

  useEffect(() => {
    fetchPicks(email);
    const status = determinePoolStatus(pool);
    setPoolStatus(status);
  }, [email, pool, getToken, location.state]);

  const findGameDate = (gameId) => {
      const game = pool.games.find(game => game.id.toString() === gameId);
      if (game) {
          return convertToLocalTime(game.start_date);
      } else {
          return "Game date not available";
      }
  };

  const copyInviteLink = async () => {
    const inviteUrl = process.env.NODE_ENV === 'development' ? 
      `http://localhost:3000/accept-invite/?poolId=${pool.poolId}` : 
      `https://moneylinez.xyz/accept-invite/?poolId=${pool.poolId}`;

    try {
      await navigator.clipboard.writeText(inviteUrl);
      toast.success("Invite link copied to clipboard!");
    } catch (err) {
      toast.error("Failed to copy invite link.");
    }
  };

  function formatForDisplay(isoDateString) {
      // Create a new Date object from the ISO string
      const date = new Date(isoDateString);

      // Format the date for display
      // You can adjust the options to get the desired output
      return date.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  }

  const convertToLocalTime = (dateString) => {
      const [date, time] = dateString.split(" ");
      const [year, month, day] = date.split("-");
      const [hours, minutes] = time.split(":");

      const dateObj = new Date(Date.UTC(year, month - 1, day, hours - 1, minutes));
      return dateObj.toLocaleString('en-US', { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
  };

  function determinePoolStatus(poolData) {
      const startDate = new Date(poolData.startDate);
      const endDate = new Date(poolData.endDate);
      const now = new Date(); // This will automatically get the current local date and time

      let status;
      if (now < startDate) {
          status = "Not Started";
      } else if (now >= startDate && now <= endDate) {
          status = "Started";
      } else {
          status = "Ended";
      }

      return status;
  }

  return (
    <div className="pool-home-container">
      <ToastContainer autoClose={5000} hideProgressBar={true} />
      <TopMenu />

      <div className="pool-details">
        <h1>{pool.poolName}</h1>
        <p>Pool Admin: {pool.email}</p>
        <p>Sport: {pool.sport.toUpperCase()}</p>
        <p>Type: {pool.poolType.charAt(0).toUpperCase() + pool.poolType.slice(1)}</p>
        <p>Start Date: {formatForDisplay(pool.startDate)}</p>
        <p>End Date: {formatForDisplay(pool.endDate)}</p>
        {(poolStatus === "Started" || poolStatus === "Ended") && <p>Pool Status: {poolStatus} <FontAwesomeIcon icon={faLock} /></p>}
        {(poolStatus === "Not Started") && <p>Pool Status: {poolStatus}</p>}
      </div>

      <div className="button-container">
        {!hasMadePicks && poolStatus === "Not Started" && (
          <div>
            <button className="button" onClick={handleMakePicks}>
              Make Your Picks
            </button>
            <p>You can edit your picks any time before the pool starts. You'll be able to view other players' picks at that time.</p>
          </div>
        )}
      </div>

      <div className="button-container">
        {hasMadePicks && poolStatus === "Not Started" && (
          <div>
            <button className="button" onClick={handleMakePicks}>
              Edit Your Picks
            </button>
            <p>You can edit your picks any time before the pool starts. You'll be able to view other players' picks at that time.</p>
          </div>
        )}
      </div>

      {isAdmin && poolStatus === "Not Started" && (
        <div className="button-container">
          <button className="button" onClick={copyInviteLink} style={{backgroundColor: '#333333'}}>
            Copy Pool Invite Link
          </button>
        </div>
      )}

      {hasMadePicks && (
        <div className="user-picks-container">
          {viewedUserPicks !== email && <h2>{`${viewedUserPicks}'s picks`}</h2>}
          {viewedUserPicks === email && <h2>{`Your picks`}</h2>}
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Team</th>
                <th style={{textAlign:'center'}}>Result</th> {/* Add class for center alignment */}
              </tr>
            </thead>
            <tbody>
              {userPicks.map(([gameId, teamName, result]) => {
                const gameDate = findGameDate(gameId);
                let resultIcon;
                if (result === 'win') {
                  resultIcon = <div style={{display:'flex', justifyContent:'center'}}>
                                  <FontAwesomeIcon icon={faCheck} className="text-success" style={{color:'green'}} />
                               </div>;
                } else if (result === 'loss') {
                  resultIcon = <div style={{display:'flex', justifyContent:'center'}}>
                                  <FontAwesomeIcon icon={faTimes} className="text-danger" style={{color:'red'}} />
                               </div>;
                } else {
                  resultIcon = '';
                }
                return gameDate !== "Game date not available" ? (
                  <tr key={gameId}>
                    <td>{gameDate}</td>
                    <td>{teamName}</td>
                    <td className="text-center">{resultIcon}</td> {/* Add class for center alignment */}
                  </tr>
                ) : null;
              })}
            </tbody>
          </table>
        </div>
      )}

      <div style={{height: 25}}></div>

      {standings.length > 0 && <div className="standings-container">
        <h2>Pool Standings</h2>
          <table>
            <thead>
              <tr>
                <th>Email</th> {/* Update header from User ID to Email */}
                <th>W</th>
                <th>L</th>
              </tr>
            </thead>
            <tbody>
              {standings.map(({ email, wins, losses }) => ( // Use email here
                <tr 
                  key={email} 
                  onClick={() => { 
                    if (hasMadePicks) { 
                      fetchPicks(email); 
                    } 
                  }}
                >
                  <td>{email}</td>
                  <td>{wins}</td>
                  <td>{losses}</td>
                </tr>
              ))}
            </tbody>
          </table>
      </div>}
    </div>
  );
};

export default PoolHome;
