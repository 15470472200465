import React, { useState, useEffect } from 'react';
import './BetSlip.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useAuth } from "@clerk/clerk-react";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const MyPools = ({ onClose, showMyPools, pools, setPools }) => {
  const navigate = useNavigate();

  const { isLoaded, isSignedIn, user } = useUser();
  const email = user.emailAddresses[0].emailAddress;
  const { getToken } = useAuth();

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    const fetchPools = async () => {
        try {
            const response = await fetch('https://api.moneylinez.xyz/get-user-pools', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await getToken()}`
                },
            });
            const data = await response.json();
            setPools(data);
        } catch (err) {
            console.error(err.message);
        }
    };

    fetchPools();
  }, []);

  const handlePoolClick = (pool) => {
    navigate(`/pool-home`, { state: { pool }});
    onClose();
  };

  const getLocalDate = () => {
      const now = new Date();
      return now.toLocaleDateString('en-CA'); // 'en-CA' format (YYYY-MM-DD) ensures consistency with ISO date format
  };

  function determinePoolStatus(poolData) {
      const startDate = new Date(poolData.startDate);
      const endDate = new Date(poolData.endDate);
      const now = new Date(); // This will automatically get the current local date and time

      let status;
      if (now < startDate) {
          status = "Not Started";
      } else if (now >= startDate && now <= endDate) {
          status = "Started";
      } else {
          status = "Ended";
      }

      return status;
  }

  return (
    <>
    {showMyPools &&
    <div className="betslip-overlay" onClick={handleOverlayClick}>

      <ToastContainer
          autoClose={5000}
          hideProgressBar={true}
      />

      <div className="betslip-content">
          <>

            <div className="deposit-item">
              <h4>
                My Pools
              </h4>
            
              {pools.length === 0 && <div 
                className="pools-container" 
                style={{display: 'flex', justifyContent: 'center'}}
              >
                You are not in any pools
              </div>}

              <div className="pools-container">
                {pools.map((pool) => {
                  const poolId = pool.poolId;
                  const poolData = pool.pool.pool;

                  const status = determinePoolStatus(poolData);

                  return (
                    <div 
                      key={pool._id} 
                      className="pool-item" 
                      onClick={() => handlePoolClick({...poolData})}
                    >
                      <p style={{ fontWeight: 'bold' }}>{poolData.poolName}</p>
                      <p>{poolData.sport.toUpperCase() + ' ' + poolData.poolType}</p>
                      <p>Status: {status}</p>
                      {poolData.email === email && (
                        <div className="admin-icon">
                          <FontAwesomeIcon icon={faUser} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <button className="close-button" onClick={onClose}>Close</button>

            </div>
          </>
      </div>
    </div>}
    </>
  );

};

export default MyPools;
